<template>
  <div class="header-menu">
    <ul class="header-menu__list">
      <li class="header-menu__item">
        <locale-router-link to="/suppliers" class="header-menu__link">
          {{ $t("suppliersCorp") }}
        </locale-router-link>
      </li>
      <li class="header-menu__item">
        <locale-router-link to="/advertisers" class="header-menu__link">
          {{ $t("advertisers") }}
        </locale-router-link>
      </li>
      <li class="header-menu__item">
        <locale-router-link to="/tenants" class="header-menu__link">
          {{ $t("forTenants") }}
        </locale-router-link>
      </li>
      <li class="header-menu__item">
        <locale-router-link to="/landlords" class="header-menu__link">
          {{ $t("landlordsOwners") }}
        </locale-router-link>
      </li>
      <li class="header-menu__item">
        <locale-router-link to="/bidding" class="header-menu__link">
          {{ $t("tradeMagnum") }}
        </locale-router-link>
      </li>
      <li class="header-menu__item">
        <locale-router-link to="/magnum-opt" class="header-menu__link">
          {{ $t("magnumOpt") }}
        </locale-router-link>
      </li>
      <li class="header-menu__item">
        <locale-router-link to="/compliance-service" class="header-menu__link">
          {{ $t("complianceService") }}
        </locale-router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HeaderMenu",
};
</script>
